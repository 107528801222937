// CSS and SASS files
import 'lazysizes';
import 'cookieconsent';
import './index.scss';
import './components/navigation';
import bulmaCarousel from './components/Carousel';

const initCarousel = () => {
  const carouselConfigs = {
    '#carousel-home': {
      slidesToScroll: 1,
      slidesToShow: 1,
      navigationSwipe: true,
      loop: true,
      autoplay: true,
      autoplaySpeed: 7000,
      pauseOnHover: true,
      pagination: true,
      infinite: true,
    },
    '#carousel-professional': {
      slidesToScroll: 1,
      slidesToShow: 3,
      navigationSwipe: true,
      loop: true,
      autoplay: true,
      autoplaySpeed: 6000,
      pauseOnHover: true,
      pagination: true,
      infinite: true,
    },
    '#carousel-fertility': {
      slidesToScroll: 1,
      slidesToShow: 1,
      navigationSwipe: true,
      loop: true,
      autoplay: true,
      pauseOnHover: true,
      autoplaySpeed: 3500,
      breakpoints: [],
    },
    '#carousel-quotation': {
      slidesToScroll: 1,
      slidesToShow: 2,
      navigationSwipe: true,
      loop: true,
      autoplay: true,
      pauseOnHover: true,
      autoplaySpeed: 5000,
      breakpoints: [{ changePoint: 500, slidesToShow: 1, slidesToScroll: 1 }],
    },
    '#carousel-talk': {
      slidesToScroll: 1,
      slidesToShow: 3,
      navigationSwipe: true,
      loop: true,
      autoplay: true,
      pauseOnHover: true,
      autoplaySpeed: 5000,
      breakpoints: [{ changePoint: 500, slidesToShow: 1, slidesToScroll: 1 }, { changePoint: 700, slidesToShow: 2, slidesToScroll: 1 }],
    },
  };
  const carousels = Object.entries(carouselConfigs)
    .map(([k, v]) => [document.querySelector(k), v])
    .filter(([k]) => Boolean(k));

  console.info('Init caroussels', carousels);
  if (carousels.length) {
    carousels.forEach(([k, v]) => bulmaCarousel.attach(k, v));
  }
};

// MODAL
const openModal = m => (m ? m.classList.toggle('is-active', true) : null);
const closeModal = m => (m ? m.classList.toggle('is-active', false) : null);

const modals = document.querySelectorAll('.modal');
function closeModals() {
  document.documentElement.classList.remove('is-clipped');
  modals.forEach(closeModal);
}

const modalCloseButtons = document.querySelectorAll('.modal-close');
if (modalCloseButtons) {
  modalCloseButtons.forEach(b => b.addEventListener('click', closeModals));
}

const imgGraph = document.querySelector('.img-graph');
if (imgGraph) {
  imgGraph.addEventListener('click', () => openModal(document.getElementById('graph-modal')));
}
// IFU
function getLanguageFromLocation() {
  return document.documentElement.lang;
}

function appendData(data, lang) {
  const pdfPath = `${LEGAL_FILE}`.replace('/legal.json', '');
  const link = document.getElementById('ifuLink');
  const ifu = data.filter(d => d.language === lang).filter(d => d.type === 'ifu');
  link.setAttribute('href', `${pdfPath}/${ifu[0].filename}`);
}

if (document.querySelector('#ifuLink')) {
  const lang = getLanguageFromLocation();
  fetch(`${LEGAL_FILE}`)
    .then(response => response.json())
    .then(data => appendData(data, lang));
}

// eslint-disable-next-line
function submitContactForm(event) {
  event.preventDefault();
  const origin = window.location.hostname === 'localhost'
    ? 'http://localhost:8000'
    : 'https://shop.production.steadysense.at';
  const object = {};
  new FormData(event.target).forEach((value, key) => {
    object[key] = value;
  });

  fetch(`${origin}/api/v1/shop/mail/`, {
    method: 'POST',
    body: JSON.stringify(object),
    headers: {
      'Content-Type': 'application/json',
      'Accept-Language': getLanguageFromLocation(),
    },
  })
    .then((r) => {
      event.target.classList.add('is-hidden');
      if (r.status === 200) {
        document.querySelector('.contact-us-success').classList.remove('is-hidden');
      } else {
        console.error(`Mail API returned status code ${r.status}`);
        r.json().then((res) => {
          let errors = '';
          Object.entries(res).forEach((e) => {
            errors += (`${e[0]}, ${e[1]}\r\n`);
            return true;
          });
          document.querySelector('.contact-us-error').textContent = errors;
          document.querySelector('.contact-us-error').classList.remove('is-hidden');
        });
      }
    })
    .catch((e) => {
      event.target.classList.add('is-hidden');
      document.querySelector('.contact-us-error').classList.remove('is-hidden');
      console.exception('Error while connection to the API', e);
    });
}

const confirmContactUsSuccess = document.querySelectorAll('.contact-us-success-btn');
if (confirmContactUsSuccess) {
  confirmContactUsSuccess.forEach(t => t.addEventListener('click', () => {
    console.log('closing....');
    // clear data entries

    closeModals();
  }));
}

const contactModalTriggers = document.querySelectorAll('.contact-us-trigger');
const contactModal = document.querySelector('#contact-us-modal');
if (contactModalTriggers && contactModal) {
  contactModalTriggers.forEach(t => t.addEventListener('click', () => {
    document.querySelector('.contact-us-error').classList.add('is-hidden');
    document.querySelector('.contact-us-success').classList.add('is-hidden');
    document.getElementById('contact-us-form').classList.remove('is-hidden');
    openModal(contactModal);
  }));
}
const contactForm = document.querySelector('#contact-us-form');
if (contactForm) {
  contactForm.addEventListener('submit', submitContactForm);
}

initCarousel();
